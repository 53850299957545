import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

export default function Seo({
  seo: {
    metaDesc: pageDescription,
    title: pageTitle,
    featuredImage,
    opengraphImage,
  } = {},
}) {
  // Get defaults
  const {
    wp: {
      generalSettings: { title: siteName, description: defaultDescription },
      seo: {
        openGraph: {
          defaultImage: { sourceUrl: defaultImage },
        },
        social: {
          twitter: { cardType: twitterCardType },
        },
      },
    },
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        wp {
          seo {
            openGraph {
              defaultImage {
                sourceUrl
              }
            }
            social {
              twitter {
                cardType
              }
            }
          }
          generalSettings {
            title
            description
          }
        }
      }
    `
  )

  const { pathname } = useLocation()

  const description = pageDescription || defaultDescription
  const title = pageTitle || siteName

  const image = `${siteUrl}${
    opengraphImage?.localFile?.publicURL || featuredImage || defaultImage
  }`

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `robots`,
          content: "index, follow",
        },
        {
          name: `viewport`,
          content: "width=device-width,initial-scale=1.0",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:url`,
          content: `${siteUrl}${pathname}`,
        },
        {
          property: `og:site_name`,
          content: siteName,
        },
        {
          property: `twitter:card`,
          content: twitterCardType,
        },
        {
          property: `twitter:image`,
          content: image,
        },
      ]}
    />
  )
}
